import React from 'react'
import { graphql } from 'gatsby'
import FadeWrapper from '../components/fadeWrapper'
import SEO from '../components/seo'
import Main from '../components/main'
import Events from '../components/events'
import Gallery from '../components/gallery'

const IndexPage = ({
  data: {
    markdownRemark: {
      frontmatter: { title, showEvents },
      html,
    },
  },
}) => (
  <FadeWrapper>
    <SEO title='Home' />
    <Main title={title} message={html} />
    {showEvents && <Events />}
    <Gallery />
  </FadeWrapper>
)

export const homepageQuery = graphql`
  query {
    markdownRemark(fileAbsolutePath: { glob: "**/src/cms/home/*.md" }) {
      frontmatter {
        title
        showEvents
      }
      html
    }
  }
`

export default IndexPage
