import React from 'react'
import Cover from '../styles/cover'

const Main = ({ title, message }) => (
  <Cover text='center'>
    <h1>{title}</h1>
    {/* eslint-disable-next-line react/no-danger */}
    <div dangerouslySetInnerHTML={{ __html: message }} />
  </Cover>
)

export default Main
